.details-banner {
  height: 246px;
  overflow: hidden;

  img {
    width: 100%;
  }
}

.mb-banner-only-md {
  display: none;
}

.h-line {
  border: 0px;
  border-bottom: $bold 2px solid;
  opacity: 0.48;
  margin-top: 5px;
}

.publication-icon {
  background: url("././images/listing-publication.svg") no-repeat;
  padding-left: 30px;
  filter: invert(0%) sepia(0%) saturate(217%) hue-rotate(169deg) brightness(20%) contrast(90%);
  background-position: 0% 60%;
  padding-top: 5px;
  padding-bottom: 5px;
}

.date-icon {
  background: url("././images/calendar.svg") no-repeat;
  padding-left: 30px;
  filter: invert(0%) sepia(0%) saturate(217%) hue-rotate(169deg) brightness(20%) contrast(90%);
  background-position: 0% 60%;
  padding-top: 5px;
  padding-bottom: 5px;
}

.location-icon {
  background: url("././images/map-pin.svg") no-repeat;
  padding-left: 30px;
  filter: invert(0%) sepia(0%) saturate(217%) hue-rotate(169deg) brightness(20%) contrast(90%);
  background-position: 0% 60%;
  padding-top: 5px;
  padding-bottom: 5px;
}

.details-wrapper-content {
  margin: 0 auto;
  width: $breakpoint-lg;
  overflow: hidden;

  .image-wrapper {
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: $border-radius;

    .profile-img-card {
      align-items: center;
      border-radius: 3px 3px 0 0;
      display: $flex;
      height: 0;
      margin: 0px;
      overflow: hidden;
      padding-top: calc(100% + 0px);
      position: relative;

      img {
        margin-top: -100%;
        mask-image: linear-gradient(180deg, rgba(0, 0, 0, .1), #000 5%, #000 50%, #000 95%, rgba(0, 0, 0, .1));
        object-fit: contain;
        transition: transform .25s ease-in-out;
        width: 100%;
        height: auto;
        border-radius: $border-radius;
      }
    }
  }

  .hideOnMobileView {
    display: $block;
  }

  .hideOnTabletView {
    display: $block;
  }

  .showOnMobileView {
    display: none;
  }

  .showOnTabletView {
    display: none;
  }

  .container {
    padding: 20px;

    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    gap: 5px;

    .details-left {
      margin-bottom: 30px;

      .details-left-content {
        .details-profile {
          margin-bottom: 180px;
          display: $block;

          .profile-pic {
            width: 320px;
            height: 320px;
            background: $secondary-lightest 0% 0% no-repeat padding-box;
            border-radius: $border-radius;
            display: $block;
            position: absolute;
            margin-top: -390px;

            img {
              width: 317px;
              height: 317px;
              padding: $base-padding;
            }
          }
        }

        .details-profile-p1 {
          margin-bottom: 400px;
          display: $block;

          .profile-pic-p1 {
            width: 320px;
            height: 320px;
            background: $secondary-lightest 0% 0% no-repeat padding-box;
            border-radius: 1px;
            display: $block;
            position: absolute;
            margin-top: -390px;

            img {
              width: 317px;
              height: 317px;
              padding: $base-padding;
            }
          }
        }

        .advt_content {
          height: auto;
          margin-top: $base-margin * 4;
        }
      }

    }

    .details-right {
      .details-right-content {
        width: 100%;

        .profile-pic {
          display: none;
        }

        .profile-pic-p1 {
          display: none;
        }

        .send-flowers {
          width: 80px;
          padding-top: 50px;
          background: url("././images/flower-button.svg") no-repeat;
          background-position: 50% 0%;
          text-align: center;
          display: inline-block;

          .sf-txt {
            width: 66px;
            height: 45px;
            text-align: center;
            font: 600 #{$base-font-size}/23px $font-secondary;
            letter-spacing: 0px;
            color: $text-dark;
            opacity: 1;
          }

          &:hover {
            background: url("././images/Flower-Button-Hover.svg") no-repeat;
            background-position: top center;
          }
        }

        .send-charity-services {
          display: inline-block;
          padding-top: 50px;
          text-align: center;
          background: url("././images/charity-services-inactive.svg") no-repeat;
          background-position: 50% 0%;

          .sf-txt {
            width: 76px;
            height: 48px;
            text-align: center;
            font: 600 #{$base-font-size}/23px $font-secondary;
            letter-spacing: 0px;
            color: $text-dark;
            opacity: 1;
          }

          &:hover {
            background: url("././images/charity-services-hover.svg") no-repeat;
            background-position: top center;
          }
        }

        .send-support-services {
          padding-top: 50px;
          background: url("././images/support-services-inactive.svg") no-repeat;
          background-position: 50% 0%;
          text-align: center;
          display: inline-block;

          .sf-txt {
            width: 88px;
            height: 48px;
            text-align: center;
            font: 600 #{$base-font-size}/23px $font-secondary;
            letter-spacing: 0px;
            color: $text-dark;
            opacity: 1;
          }

          &:hover {
            background: url("././images/support-services-hover.svg") no-repeat;
            background-position: top center;
          }
        }

        .send-memorial-services {
          padding-top: 50px;
          background: url("././images/memorial-services-inactive.svg") no-repeat;
          background-position: 50% 0%;
          text-align: center;
          margin: 40px 25px 20px 0px;
          display: inline-block;

          .sf-txt {
            width: 78px;
            height: 48px;
            text-align: center;
            font: 600 #{$base-font-size}/23px $font-secondary;
            letter-spacing: 0px;
            color: $text-dark;
            opacity: 1;
          }

          &:hover {
            background: url("././images/memorial-services-hover.svg") no-repeat;
            background-position: top center;
          }
        }

        .candle {
          display: inline-block;

          width: 80px;
          padding-top: 50px;
          text-align: center;
          cursor: pointer;
          position: relative;

          .light-a-btn {
            position: absolute;
            top: 0;
            left: 0;
            visibility: hidden;
          }
        }

        .light-candle {
          background: url("././images/candle-inactive.svg") no-repeat;
          background-position: 50% 0%;

          .candle-count {
            width: 32px;
            height: 23px;
            color: $white;
            background: $primary;
            letter-spacing: 0.05px;
            font: 600 #{$base-font-size}/24px $font-secondary;
            border-radius: $border-radius - 5;

            position: absolute;
            right: 0px;
            top: -4px;
          }

          .candle-sf-txt {
            color: #414042;
            font: 600 18px / 23px Poppins, sans-serif;
          }

          &:hover {
            background: url("././images/candle-hover.svg") no-repeat;
            background-position: top center;
          }
        }

        .light-candle-lit {
          background: url("././images/candle-active.svg") no-repeat;
          background-position: 50% 0%;

          .candle-count {
            background: $primary-lightest;
            border: 1px solid $primary;
            color: $primary;
          }

          &:hover {
            background: url("././images/candle-active.svg") no-repeat;
            background-position: top center;
          }
        }

        .add-to-calendar-container {
          .add-to-calendar-button {
            width: 85px;
            text-align: center;

            display: flex;
            justify-content: center;
            align-items: center;
            gap: 6px;
            flex-direction: column;

            &:hover {
              cursor: pointer;

              svg {
                background-color: $primary-lightest;
              }
            }

            svg {
              height: 45px;
              width: 45px;
              border-radius: 50%;
            }

            span {
              color: $text-dark;
              font: 600 18px/22px $font-secondary;
            }
          }

          .add-to-calendar-overlay-container {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: 10000;

            display: none;
            justify-content: center;
            align-items: center;

            &.active {
              display: flex;
            }

            .add-to-calendar-overlay-content {
              position: relative;
              width: 390px;
              height: 265px;
              padding: 40px;
              text-align: center;
              background: $secondary-lightest;
              border-radius: 5px;
              border: 1px solid $secondary-dark;

              .calendar-choice-text {
                padding-bottom: 35px;
                font: 600 34px/normal $font-primary;

                span {
                  font: italic 400 34px/normal $font-primary;
                }
              }

              .calendar-choice-container {
                display: flex;
                justify-content: space-between;
                align-items: center;

                svg {
                  transition: all 0.3s ease-in-out;

                  &:hover {
                    scale: 1.1;
                    cursor: pointer;
                  }
                }

                .office-icon {
                  display: none;
                }
              }

              .calendar-close-icon {
                position: absolute;
                top: -46px;
                right: 0;

                width: 34px;
                height: 34px;
                background: $white;
                border-radius: 50%;
                opacity: .5;
                cursor: pointer;

                display: flex;
                justify-content: center;
                align-items: center;

                &:hover {
                  background: $text-dark;

                  .cross-icon {
                    filter: invert(0);
                  }
                }

                .cross-icon {
                  height: 24px;
                  width: 24px;
                  background: url("././images/cross-icon.svg") no-repeat;
                  filter: invert(1);
                }
              }
            }
          }
        }

        .inner-content {
          .inner-content-header {
            margin: 5px 0 20px 0;

            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: auto;

            .header-block {
              .header-share-block {
                display: flex;
                justify-content: space-between;

                @keyframes horizontal-shake-animation {
                  0% {
                    transform: translateX(0);
                  }

                  25% {
                    transform: translateX(-10px);
                  }

                  50% {
                    transform: translateX(10px);
                  }

                  75% {
                    transform: translateX(-10px);
                  }

                  100% {
                    transform: translateX(0);
                  }
                }

                .main-header {
                  flex: 1;

                  h2 {
                    color: $text-dark;
                    font: italic normal #{$base-font-size + 6}/24px $font-primary;
                  }

                  h1 {
                    margin: 0;
                    color: $text-dark;
                    font: 600 calc(2*$base-font-size + 4px)/40px $font-primary;
                    margin-bottom: 20px;
                    margin-top: 15px;
                  }
                }

                .share-block {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  gap: 5px;

                  width: 120px;
                  height: 45px;
                  border-radius: 32px;
                  border: 1px solid $secondary-dark;
                  cursor: pointer;

                  &.share-block-shake-animation {
                    animation: horizontal-shake-animation .25s ease-in-out 30s 8;
                  }

                  &:hover {
                    background: $secondary;

                    .share-icon {
                      filter: grayscale(100%) brightness(35%) contrast(100%);
                    }

                    .share-text {
                      color: $text-dark;
                    }
                  }

                  .share-text {
                    color: $secondary-dark;
                    font: 600 #{$base-font-size}/normal $font-secondary;
                  }
                }
              }



              .mb-date-range {
                display: none;
                color: $text-dark;
                font: bold #{$base-font-size}/25px $font-secondary;
              }

              .heading-separator {
                border: 0px;
                border-bottom: 1px solid $secondary-dark;
                margin: $base-margin - 5 0px $base-margin * 2 + 2;
              }

              .mb-photo-list {
                display: none;
                height: 80px;
                margin: 10px 0 -30px 0;

                .g-txt {
                  padding-left: 5px;
                  vertical-align: $middle;
                }
              }
            }

            .publication-details {
              margin-bottom: 20px;

              display: flex;
              align-items: center;
              gap: 25px;

              .publication-name-text,
              .publication-date-text,
              .publication-location-text {
                color: $text-dark;
                font: bold calc($base-font-size - 1px)/26px $font-secondary;
              }
            }

            .inner-content-desc {
              width: 823px;
              text-align: left;
              color: $text-dark;
              font: #{$base-font-size}/24px $font-secondary;
            }

            .date-range {
              display: block;
              color: $text-dark;
              font: bold calc($base-font-size + 5px)/25px $font-primary;
            }

            .partner-services {
              margin: 50px 0;

              display: flex;
              flex-wrap: wrap;
              gap: 25px;
            }

            .mb-share-block {
              display: none;
              justify-content: center;
              align-items: center;
              gap: 5px;

              height: 45px;
              margin-bottom: 10px;
              border-radius: 32px;
              border: 1px solid $secondary-dark;

              &.share-block-shake-animation {
                animation: horizontal-shake-animation .25s ease-in-out 30s 8;
              }

              &:hover {
                background: $secondary;

                .share-icon {
                  filter: grayscale(100%) brightness(35%) contrast(100%);
                }

                .share-text {
                  color: $text-dark;
                }
              }

              .share-text {
                color: $secondary-dark;
                font: 600 #{$base-font-size}/normal $font-secondary;
              }
            }

            .social-share-overlay-container {
              position: fixed;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.5);
              z-index: 10000;

              display: none;
              justify-content: center;
              align-items: center;

              &.active {
                display: flex;
              }

              .social-share-overlay-content {
                position: relative;
                width: 483px;
                height: 354px;
                padding-top: 50px;
                text-align: center;
                background: $secondary-lightest;
                border-radius: 5px;
                border: 1px solid $secondary-dark;

                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 30px;

                &:has(.opengraph-image) {
                  height: 574px;
                  padding: 10px;

                  .opengraph-image {
                    width: 463px;
                    height: 247px;
                    border-radius: 5px;
                    border: 1px solid $secondary-dark;
                  }
                }

                .social-share-question {
                  padding: 0 20px;
                  color: $text-dark;
                  font: 600 34px/normal $font-primary;

                  span {
                    font-weight: 400;
                    font-style: italic;
                  }
                }

                .social-share-buttons {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  gap: 25px;

                  .facebook-share-button,
                  .x-share-button,
                  .mail-share-button {
                    height: 45px;
                    width: 45px;
                    background: $light-secondary;
                    border-radius: 50%;
                    border: 1px solid $primary;
                    cursor: pointer;

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &:hover {
                      background: $primary;

                      svg {
                        fill: $white;
                      }
                    }

                    svg {
                      fill: $primary;
                    }
                  }
                }

                .share-link-actions {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  gap: 2px;

                  .share-link {
                    flex: 1;
                    display: flex;
                    align-items: center;

                    width: 313px;
                    height: 45px;
                    padding-left: 18px;
                    color: $light-grey;
                    font: 400 #{$base-font-size}/normal $font-secondary;
                    border-radius: 100px 5px 5px 100px;
                    border: 1px solid $lightest-grey;

                    span {
                      width: 286px;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    }
                  }

                  .share-link-copy-button {
                    width: 108px;
                    height: 45px;
                    color: $white;
                    background: $primary;
                    font: 600 #{$base-font-size}/normal $font-secondary;
                    border-radius: 5px 32px 32px 5px;
                    border: 1px solid $primary;
                    cursor: pointer;

                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 5px;
                  }
                }

                .link-copied-toast {
                  position: absolute;
                  // to hide parent container border
                  bottom: -1px;
                  left: 50%;
                  transform: translateX(-50%);

                  width: 161px;
                  height: 38px;
                  color: $white;
                  font: 600 #{$base-font-size}/normal $font-secondary;
                  border-radius: 5px 5px 0px 0px;
                  background: $primary;
                  opacity: 0;
                  transition: opacity .5s ease-in-out;


                  display: flex;
                  justify-content: center;
                  align-items: center;

                  &.active {
                    opacity: 1;
                  }
                }

                .close-icon {
                  position: absolute;
                  top: -46px;
                  right: 0;

                  width: 34px;
                  height: 34px;
                  background: $white;
                  border-radius: 50%;
                  opacity: .5;
                  cursor: pointer;

                  display: flex;
                  justify-content: center;
                  align-items: center;

                  &:hover {
                    background: $text-dark;

                    .cross-icon {
                      filter: invert(0);
                    }
                  }

                  .cross-icon {
                    height: 24px;
                    width: 24px;
                    background: url("././images/cross-icon.svg") no-repeat;
                    filter: invert(1);
                  }
                }
              }
            }
          }
        }

        .right-standalone-partner {
          display: none;
        }

        .funeral-related-notices {
          width: 897px;
          padding: 20px;
          background: $secondary;
          border: 1px solid $secondary-dark;
          border-radius: $border-radius-sm;
          margin-bottom: 50px;

          h3 {
            height: 45px;
            margin-left: 0px;
            font: bold calc($base-font-size + 7px)/33px $font-primary;
            letter-spacing: 0.07px;
            color: $text-color;
          }

          .slider {
            margin: 0px auto;
          }

          .card-prev {
            left: -1%;
            bottom: 150px;
            border: 1px solid $primary;
            border-radius: 50%;
            z-index: 1;
          }

          .card-next {
            bottom: 150px;
            right: 0%;
            border: 1px solid $primary;
            border-radius: 50%;
            z-index: 1;
          }

          .card-initialized .card-slide {
            display: $block;
            width: 244px;

            img {
              display: $block;
              width: 244px;
              height: 347px;
            }
          }

          .listing-related-notices {
            padding: 20px;

            .card {
              width: 244px;
              height: 352px;
              margin: 0;
              border: 1px solid $secondary-dark;
              border-radius: $border-radius-sm;
              box-shadow: none;

              a {
                .card-block {
                  overflow: hidden;

                  .funeral-banner-img-card {
                    align-items: center;
                    border-radius: 3px 3px 0 0;
                    display: $flex;
                    height: 0;
                    margin: 0;
                    overflow: hidden;
                    padding-top: calc(100% + 25px);
                    position: relative;

                    img {
                      margin-top: -100%;
                      mask-image: linear-gradient(180deg, rgba(0, 0, 0, .1), #000 5%, #000 50%, #000 95%, rgba(0, 0, 0, .1));
                      object-fit: contain;
                      transition: transform .25s ease-in-out;
                      width: 100%;
                      height: auto;
                      z-index: 1;
                    }
                  }
                }

                .card-details {
                  padding: 10px;
                  text-align: left;

                  h4 {
                    font: 600 $base-font-size /normal $font-secondary;
                    color: $text-dark;
                    margin-left: 0px;
                    width: 215px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                  }

                  span {
                    font: 400 $base-font-size /normal $font-secondary;
                    color: $secondary-dark;
                  }
                }
              }

              &.obituary-card a {
                position: relative;

                .card-block {
                  height: 100%;

                  .funeral-banner-img-card {
                    height: 100%;
                    padding-top: 0;

                    img {
                      height: 100%;
                      margin-top: 0;
                      object-fit: cover;
                    }
                  }
                }

                .card-details {
                  width: 244px;
                  position: absolute;
                  bottom: 0;
                  padding-bottom: 20px;
                  mix-blend-mode: hard-light;
                  background: linear-gradient(90deg, rgba(90, 90, 91, 0.95) 50%, rgba(90, 90, 91, 0.00) 88%);
                  z-index: 1;

                  h4 {
                    color: white;
                  }

                  span {
                    color: white;
                    font: italic 400 #{$base-font-size}/normal $font-primary;
                  }
                }
              }
            }

            .card-dots {
              bottom: -10px;

              li button {
                width: 10px;
                height: 10px;
                background: $light-secondary;
                font-size: 0px;
                border: 1px solid $primary;
                border-radius: 50%;

                &::before {
                  display: none;
                }
              }

              .card-active button {
                background: $primary;
              }
            }
          }
        }
      }
    }
  }
}


// modal code starts here
.noticeImageGallery {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10001;
}

/* The Modal (background) */
.modal {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: $light-black-rgba;

  .modal-content {
    background: $white;
    border-radius: $border-radius-sm;
    height: auto;
    margin: 0px auto;
    width: 820px;
    position: relative;
    top: 15%;

    /* The Close Button */
    .close {
      color: $black;
      background-color: $light-black-rgba;
      border-radius: 50%;
      color: $white;
      height: 45px;
      padding: 9px 7px 0px;
      position: absolute;
      float: right;
      right: 0px;
      display: $block;
      width: 45px;
      z-index: 999;
      margin: $base-margin - 1;
      font: calc(3*$base-font-size - 4px)/30px $font-secondary;
    }
  }
}

.imgGalleryLinkContainer {
  z-index: 1;
  margin-top: -305px;
  margin-left: 165px;
}

.btn-gallery-icon {
  background: url("././images/gallery-icon.svg") no-repeat;
  vertical-align: $middle;
  padding-left: 20px;
  background-size: 20px;
  background-position: 0% 50%;
}


.btn-gallery {
  background-color: $primary;
  border: 0;
  border-radius: 25px;
  color: $white;
  cursor: pointer;
  display: $block;
  outline: none;
  padding: 10px;
  position: relative;
  text-decoration: none;
  transition: width .5s;
  vertical-align: text-top;
  height: 40px;
  text-align: left;
}

.btn-gallery .btn-gallery-text {
  max-width: 0;
  display: inline-block;
  transition: color .25s 1.5s, max-width 2s;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  color: $white;
  padding-left: 5px;
  font: bold calc($base-font-size - 4px)/22px $font-secondary;
}

.btn-gallery:hover .btn-gallery-text {
  max-width: 300px;
  color: $white;
}


.close:hover,
.close:focus {
  color: $black;
  text-decoration: none;
  cursor: pointer;
}

// modal code ends here

// When the screen width >= 1920px, render the 1920px display
@include for-xl-up {
  .details-wrapper-content {
    width: $breakpoint-lg;
    margin: 0 auto;
  }
}

// When the screen width < 1280px and >= 768px, render the 768px display
@include for-lg-only {
  .details-banner {
    height: 190px;
  }

  .details-wrapper-content {
    width: $breakpoint-md;

    .hideOnMobileView {
      display: $block;
    }

    .showOnMobileView {
      display: none;
    }

    .hideOnTabletView {
      display: none;
    }

    .showOnTabletView {
      display: $block;
    }

    .container {
      width: 100%;
      padding: 20px;

      .details-left {
        width: 43%;

        .details-left-content {
          .details-profile {
            .profile-pic {
              margin-top: -360px;
            }
          }

          .advt_content {
            height: auto;
          }
        }
      }

      .details-right {
        width: 55%;

        .details-right-content {
          width: 100%;

          .profile-pic {
            display: none;
          }

          .profile-pic-p1 {
            display: none;
          }

          .send-flowers {
            display: inline-block;
            padding-top: 50px;

            .sf-txt {
              width: 66px;
              height: 45px;
              text-align: center;
              font: 600 #{$base-font-size}/23px $font-secondary;
              letter-spacing: 0px;
              color: $text-dark;
              opacity: 1;
            }
          }

          .candle {
            width: 80px;
          }

          .inner-content {
            .inner-content-header {
              .header-block .header-share-block .main-header {
                h2 {
                  font: italic normal calc($base-font-size + 4px)/22px $font-primary;
                }

                h1 {
                  font: 600 calc($base-font-size + 10px)/42px $font-primary;
                }
              }

              .publication-details {
                align-items: flex-start;
                flex-direction: column;
                gap: 10px;
              }

              .inner-content-desc {
                width: 365px;
                margin: 10px 0;
              }

              .date-range {
                font-size: calc($base-font-size + 2px);
              }
            }
          }

          .right-standalone-partner {
            display: none;
          }

          .funeral-related-notices {
            width: 380px;
            padding: 20px;
            background: $secondary 0% 0% no-repeat padding-box;
            border-radius: $border-radius-sm;
            margin-bottom: 50px;

            .slider {
              margin: 0px auto;
            }

            .card-prev {
              left: 0%;
              bottom: 120px;
              z-index: 1;
            }

            .card-next {
              bottom: 115px;
              right: 0%;
              z-index: 1;
            }

            .card-initialized .card-slide {
              display: $block;
              width: 178px;

              img {
                display: $block;
                width: 178px;
                height: 188px;
              }
            }

            .listing-related-notices {
              padding: 20px;

              .card {
                width: 178px;
                height: 252px;

                a {
                  .card-block .funeral-banner-img-card {
                    padding-top: calc(100% + 6px);
                  }

                  .card-details h4 {
                    width: 150px;
                  }
                }

                &.obituary-card a .card-details {
                  width: 178px;
                  padding-bottom: 6px;
                }
              }
            }
          }
        }
      }
    }
  }
}


// When the screen width < 768px and >= 428px, render the 428px display
@include for-md-only {
  .details-banner {
    height: 180px;
    width: 428px;
  }

  .mb-banner-above-xl {
    display: none;
  }

  .mb-banner-only-md {
    display: block;
  }

  span#viewGallery {
    padding: 16px 55px 17px 54px;
    font: 600 #{$base-font-size}/27px $font-secondary;
    margin: 0 auto;
  }

  .home-icon,
  .ph-icon,
  .email-icon {
    background: none;
  }

  .gallery-icon {
    background: url("././images/home.svg") no-repeat;
    vertical-align: text-top;
    padding: 15px;
    background-position: 50%;
    background-size: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .details-wrapper-content {
    width: $breakpoint-sm;
    margin: 0 auto;

    .hideOnMobileView,
    .showOnTabletView {
      display: none;
    }

    .showOnMobileView {
      display: $block;
    }

    .container {
      padding: 20px;
      width: 100%;

      .details-left {
        float: none;
        display: $block;
        margin-bottom: 15px;

        .details-left-content,
        .details-profile-p1,
        .advt_content,
        .standalone-partner,
        .details-profile {
          display: none;
        }
      }

      .details-right {
        float: none;
        display: $block;
        margin-bottom: 15px;
        padding: 0px;

        .details-right-content {
          margin-top: -140px;
          width: 100%;

          .profile-pic {
            display: block;
            width: 200px;
            height: 200px;
            background: $secondary-lightest 0% 0% no-repeat padding-box;
            border-radius: 0px;
            left: 50%;
            position: relative;
            top: 0px;
            transform: translate(-50%, 0%);

            .image-wrapper {
              border-radius: 0px;
              border: 5px solid $secondary-lightest;

              .profile-img-card {
                border-radius: 0px;
              }
            }

            img {
              border-radius: 0px;
              bottom: 0;
              left: 0;
              margin: auto;
              max-height: 100%;
              max-width: 100%;
              position: absolute;
              right: 0;
              top: 0;
            }
          }

          .profile-pic-p1 {
            display: block;
            width: 200px;
            height: 200px;
            background: $secondary-lightest 0% 0% no-repeat padding-box;
            border-radius: 0px;
            position: relative;
            top: 0px;
            left: 50%;
            margin-top: $base-margin * 10 + $base-margin * 4;
            transform: translate(-50%, 0%);

            .image-wrapper {
              border-radius: 0px;
              border: 5px solid $secondary-lightest;

              .profile-img-card {
                border-radius: 0px;
              }
            }

            img {
              border-radius: 0px;
              bottom: 0;
              left: 0;
              margin: auto;
              max-height: 100%;
              max-width: 100%;
              position: absolute;
              right: 0;
              top: 0;
            }
          }

          .inner-content {
            margin-top: 190px;

            .inner-content-header {
              margin-top: -170px;

              .header-block {
                margin-bottom: 50px;
                grid-row: 1;

                .header-share-block {
                  justify-content: center;

                  .main-header {
                    h2 {
                      text-align: center;
                    }

                    h1 {
                      text-align: center;
                      font: 600 32px/48px $font-primary;
                    }
                  }

                  .share-block {
                    display: none;
                  }
                }

                .mb-date-range {
                  display: grid;
                  text-align: center;
                }

                .heading-separator {
                  display: none;
                }

                .mb-photo-list {
                  display: flex;
                  align-items: center;
                }
              }

              .publication-details {
                grid-row: 2;
                flex-direction: column;
                gap: 16px;
              }

              .inner-content-desc {
                width: 100%;
                font: 300 calc($base-font-size - 2px)/25px $font-secondary;
                margin: 20px 0;

                grid-row: 4;
              }

              .date-range {
                display: none;
              }

              .partner-services {
                margin: 30px 0;

                grid-row: 3;
                justify-content: space-evenly;
              }

              .mb-share-block {
                display: flex;
              }

              .social-share-overlay-container {
                .social-share-overlay-content {
                  width: 340px;
                  height: 300px;

                  &:has(.opengraph-image) {
                    height: 450px;

                    .opengraph-image {
                      width: 330px;
                      height: 169px;
                    }
                  }

                  .social-share-question {
                    font-size: 23px;
                  }

                  .social-share-buttons {

                    .facebook-share-button,
                    .x-share-button,
                    .mail-share-button {
                      height: 38px;
                      width: 38px;
                    }
                  }

                  .share-link-actions {
                    .share-link {
                      width: 204px;
                      height: 39px;
                      font-size: 14px;

                      span {
                        width: 182px;
                      }
                    }

                    .share-link-copy-button {
                      width: 94px;
                      height: 39px;
                      font-size: 16px;
                    }
                  }

                  .link-copied-toast {
                    font-size: 16px;
                  }
                }
              }
            }
          }

          .funeral-related-notices {
            width: 380px;
            padding: 20px;
            background: $secondary 0% 0% no-repeat padding-box;
            border-radius: $border-radius-sm;
            margin-bottom: 50px;

            .slider {
              margin: 0px auto;
            }

            .card-prev {
              left: 0%;
              bottom: 120px;
              z-index: 1;
            }

            .card-next {
              bottom: 115px;
              right: 0%;
              z-index: 1;
            }

            .card-initialized .card-slide {
              display: $block;
              width: 178px;

              img {
                display: $block;
                width: 178px;
                height: 188px;

              }
            }

            .listing-related-notices {
              padding: 20px;

              .card {
                width: 178px;
                height: 252px;

                a {
                  .card-block .funeral-banner-img-card {
                    padding-top: calc(100% + 8px);
                  }

                  .card-details h4 {
                    width: 150px;
                  }
                }

                &.obituary-card a .card-details {
                  padding-bottom: 6px;
                }
              }

              .card-dots {
                right: 5px;
              }
            }
          }
        }
      }
    }
  }
}

// When the screen width < 428px, render the 320px display
@include for-sm-down {
  .details-banner {
    height: 180px;
    width: 100%;
  }

  .mb-banner-above-xl {
    display: none;
  }

  .mb-banner-only-md {
    display: block;
  }

  span#viewGallery {
    padding: 16px 55px 17px 54px;
    font: 600 #{$base-font-size}/27px $font-secondary;
    margin: 0 auto;
  }

  .home-icon,
  .ph-icon,
  .email-icon {
    background: none;
  }

  .gallery-icon {
    background: url("././images/home.svg") no-repeat;
    vertical-align: text-top;
    padding: 15px;
    background-position: 50%;
    background-size: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .details-wrapper-content {
    width: 100%;
    margin: 0 auto;

    .image-wrapper .profile-img-card {
      height: 100%;
      padding-top: 0%;
    }

    .hideOnMobileView,
    .showOnTabletView {
      display: none;
    }

    .showOnMobileView {
      display: $block;
    }

    .container {
      padding: 20px;
      width: 100%;

      .details-left {
        float: none;
        display: $block;
        margin-bottom: 15px;

        .details-left-content,
        .details-profile-p1,
        .advt_content,
        .standalone-partner,
        .details-profile {
          display: none;
        }
      }

      .details-right {
        display: $block;
        float: none;
        width: 100%;
        margin-bottom: 15px;
        padding: 0px;

        .details-right-content {
          margin-top: -140px;
          width: 100%;

          .profile-pic {
            display: block;
            width: 200px;
            height: 200px;
            background: $secondary-lightest 0% 0% no-repeat padding-box;
            border-radius: 0%;
            position: relative;
            top: 0px;
            left: 50%;
            transform: translate(-50%, 0%);

            .image-wrapper {
              border-radius: 0%;
              border: 5px solid $secondary-lightest;

              .profile-img-card {
                border-radius: 0px;
              }
            }

            img {
              border-radius: 0%;
              bottom: 0;
              left: 0;
              margin: auto;
              max-height: 100%;
              max-width: 100%;
              position: absolute;
              right: 0;
              top: 0;
            }
          }

          .profile-pic-p1 {
            display: block;
            width: 200px;
            height: 200px;
            background: $secondary-lightest 0% 0% no-repeat padding-box;
            border-radius: 0%;
            position: relative;
            top: 0px;
            left: 50%;
            margin-top: $base-margin * 10 + $base-margin * 4;
            transform: translate(-50%, 0%);

            .image-wrapper {
              border-radius: 0%;
              border: 5px solid $secondary-lightest;

              .profile-img-card {
                border-radius: 0px;
              }
            }

            img {
              border-radius: 0%;
              bottom: 0;
              left: 0;
              margin: auto;
              max-height: 100%;
              max-width: 100%;
              position: absolute;
              right: 0;
              top: 0;
            }
          }

          .inner-content {
            margin-top: 190px;

            .inner-content-header {
              margin-top: -170px;

              .header-block {
                margin-bottom: 50px;
                grid-row: 1;

                .header-share-block {
                  justify-content: center;

                  .main-header {
                    h2 {
                      text-align: center;
                    }

                    h1 {
                      text-align: center;
                      font: 600 32px/48px $font-primary;
                    }
                  }

                  .share-block {
                    display: none;
                  }
                }

                .mb-date-range {
                  display: grid;
                  text-align: center;
                }

                .heading-separator {
                  display: none;
                }

                .mb-photo-list {
                  display: flex;
                  align-items: center;

                }
              }

              .publication-details {
                grid-row: 2;
                flex-direction: column;
                gap: 16px;
              }

              .inner-content-desc {
                width: 100%;
                font: 300 calc($base-font-size - 2px)/25px $font-secondary;
                margin: 20px 0;

                grid-row: 4;
              }

              .date-range {
                display: none;
              }

              .partner-services {
                margin: 30px 0;

                grid-row: 3;
                justify-content: space-evenly;
              }

              .mb-share-block {
                display: flex;
              }

              .social-share-overlay-container {
                .social-share-overlay-content {
                  width: 320px;
                  height: 300px;

                  &:has(.opengraph-image) {
                    height: 450px;

                    .opengraph-image {
                      width: 300px;
                      height: 169px;
                    }

                    .social-share-question {
                      padding: 0 10px;
                    }
                  }

                  .social-share-question {
                    font-size: 23px;
                  }

                  .social-share-buttons {

                    .facebook-share-button,
                    .x-share-button,
                    .mail-share-button {
                      height: 38px;
                      width: 38px;
                    }
                  }

                  .share-link-actions {
                    .share-link {
                      width: 204px;
                      height: 39px;
                      font-size: 14px;

                      span {
                        width: 182px;
                      }
                    }

                    .share-link-copy-button {
                      width: 94px;
                      height: 39px;
                      font-size: 16px;
                    }
                  }

                  .link-copied-toast {
                    font-size: 16px;
                  }
                }
              }
            }
          }

          .funeral-related-notices {
            margin-top: 0px;
            width: 100%;
            padding: $base-padding;
            background: $secondary 0% 0% no-repeat padding-box;
            border-radius: $border-radius-sm;
            margin-bottom: 50px;

            h3 {
              height: 45px;
              margin-left: 0px;
              font: bold calc($base-font-size + 7px)/33px $font-primary;
              letter-spacing: 0.07px;
              color: $text-color;
            }

            .slider {
              margin: 0px auto;
            }

            .card-prev,
            .card-next {
              bottom: 135px;
              z-index: 1;
            }

            .card-prev {
              left: 0%;
            }

            .card-next {
              right: 0%;
            }


            .card-initialized .card-slide {
              display: $block;
              width: 184px;

              img {
                display: $block;
                width: 184px;
                height: 188px;
              }
            }

            .listing-related-notices {
              padding: 20px;

              .card {
                width: 184px;
                height: 261px;

                a {
                  .card-block .funeral-banner-img-card {
                    padding-top: calc(100% + 8px);
                  }

                  .card-details h4 {
                    width: 150px;
                  }
                }

                &.obituary-card a .card-details {
                  width: 184px;
                  padding-bottom: 8px;
                }
              }

              .card-dots {
                bottom: -8px;
                right: 5px;
              }
            }
          }
        }
      }
    }
  }
}