.myaccount-inputfield {
  width: 75%;

  display: flex;
  flex-direction: column;
  gap: 10px;

  &.error-state input {
    border-color: $bright-red !important;
  }

  label {
    color: $text-dark;
    font: 600 18px/normal $font-poppins;
  }

  input {
    height: 50px;
    color: $text-dark;
    font: 400 18px/normal $font-secondary;
    background: transparent;
    outline: none;
    border: none;
    border-bottom: 1px solid $secondary-dark;
  }
}

.myaccount-button {
  width: 172px;
  height: 50px;
  color: $primary;
  font: 600 18px/normal $font-secondary;
  background: transparent;
  border-radius: 100px;
  border: 1px solid $primary;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
}

.mb-myaccount-menu {
  display: none;
}

.myaccount-container {
  width: 1280px;
  margin: 50px auto;
  padding: 0 20px;

  display: flex;
  gap: 30px;

  .myaccount-left-container {
    width: 317px;

    .myaccount-image-container {
      margin-bottom: 30px;
      padding: 20px;
      background: $secondary;
      border-radius: $border-radius-sm;
      border: 1px solid #B9978D;

      .myaccount-image {
        position: relative;
        width: 257px;
        height: 255px;
        margin: 0 auto;

        .change-image-button {
          position: absolute;
          top: 20px;
          right: 20px;
          height: 40px;
          width: 40px;
          background: url("./images/camera-icon.svg") no-repeat center;
          border: none;
          cursor: pointer;

          &:hover {
            filter: brightness(.9);
          }
        }

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-radius: 157.5px;
        }
      }

      .myaccount-menu {
        margin: 30px 0;

        display: flex;
        flex-direction: column;
        gap: 15px;

        a {
          height: 45px;
          padding: 0 20px;
          color: $text-dark;
          font: 400 18px/normal $font-secondary;
          background: transparent;
          border-radius: 100px;
          border: none;
          cursor: pointer;

          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 20px;

          &:hover {
            background: $secondary-lightest;
          }

          &.active {
            color: $white;
            background: $primary;
            border: 1px solid $primary;

            svg g path {
              fill: $white;
            }
          }

          svg g path {
            fill: $text-dark;
          }
        }
      }
    }

    .myaccount-advertisement {
      overflow: hidden;
    }
  }

  .myaccount-right-container {
    flex: 1;

    h1 {
      margin-top: -5px;
      color: $text-dark;
      font: 600 40px/1 $font-primary;
    }

    .mb-myaccount-image {
      display: none;
    }

    .myaccount-login-details {
      margin-bottom: 50px;
      padding: 40px;
      border-radius: 5px;
      border: 1px solid $secondary-dark;

      h2 {
        color: $text-dark;
        font: 600 22px/1 $font-secondary;
      }

      .myaccount-login-details-form {
        margin-top: 30px;

        display: flex;
        flex-direction: column;
        gap: 45px;

        &.active {
          .myaccount-inputfield {
            gap: 30px;

            input {
              padding: 0 25px;
              border-radius: 100px;
              border: 1px solid $secondary-dark;
            }
          }

          .password-inputfield {
            display: none;
          }

          .oldpassword-inputfield {
            display: flex;
            position: relative;

            &.error-state {
              &::after {
                content: "The current password you entered is incorrect.";
                position: absolute;
                bottom: -30px;
                left: 25px;
                color: $bright-red;
                font: 400 14px/normal $font-secondary;
              }
            }
          }

          .newpassword-inputfield {
            display: flex;
            position: relative;

            &.error-state {
              &::after {
                content: "Your new password did not meet requirements.";
                position: absolute;
                bottom: -30px;
                left: 25px;
                color: $bright-red;
                font: 400 14px/normal $font-secondary;
              }
            }
          }

          .confirmpassword-inputfield {
            display: flex;
            position: relative;

            &.error-state {
              &::after {
                content: "The new password and confirm password do not match.";
                position: absolute;
                bottom: -30px;
                left: 25px;
                color: $bright-red;
                font: 400 14px/normal $font-secondary;
              }
            }
          }
        }

        .oldpassword-inputfield,
        .newpassword-inputfield,
        .confirmpassword-inputfield {
          display: none;
        }

        .myaccount-login-details-buttons {
          display: flex;
          align-items: center;
          gap: 30px;

          &.active {
            .edit-button {
              display: none;
            }

            .cancel-button,
            .save-button {
              display: flex;
            }
          }

          .edit-button {
            display: flex;
            color: $white;
            background: $primary;
          }

          .cancel-button,
          .save-button {
            display: none;
          }

          .save-button {
            color: $white;
            background: $primary;
          }
        }

        .general-error-message {
          width: 75%;
          height: 54px;
          margin-bottom: -20px;
          padding: 0 15px;
          color: $bright-red;
          font: 600 18px/normal $font-secondary;
          background: $dark-red;
          border-radius: 5px;
          border: 1px solid $bright-red;

          display: flex;
          align-items: center;
          gap: 5px;
        }
      }
    }

    .myaccount-personal-details {
      padding: 40px;
      border-radius: 5px;
      border: 1px solid $secondary-dark;

      h2 {
        color: $text-dark;
        font: 600 22px/1 $font-secondary;
      }

      .myaccount-personal-details-form {
        margin-top: 30px;

        display: flex;
        flex-direction: column;
        gap: 45px;

        &.active {
          .myaccount-inputfield {
            gap: 30px;

            input {
              padding: 0 25px;
              border-radius: 100px;
              border: 1px solid $secondary-dark;
            }
          }
        }

        .myaccount-personal-details-buttons {
          display: flex;
          align-items: center;
          gap: 30px;

          &.active {
            .edit-button {
              display: none;
            }

            .cancel-button,
            .save-button {
              display: flex;
            }
          }

          .edit-button {
            display: flex;
            color: $white;
            background: $primary;
          }

          .cancel-button,
          .save-button {
            display: none;
          }

          .save-button {
            color: $white;
            background: $primary;
          }
        }
      }
    }
  }
}

.mb-myaccount-advertisement {
  display: none;
}

// For screen width from 1280px to 768px
@include for-lg-only {
  .myaccount-inputfield {
    width: 100%;
  }

  .myaccount-container {
    width: 768px;

    .myaccount-left-container {
      width: 275px;

      .myaccount-image-container {
        .myaccount-image {
          height: 225px;
          width: 225px;

          .change-image-button {
            top: 20px;
            right: 10px;
          }
        }
      }

      .myaccount-advertisement .ad-block.ad-halfpage {
        width: 100%;
        padding: 0;
      }
    }

    .myaccount-right-container {

      .myaccount-login-details,
      .myaccount-personal-details {
        padding: 40px 20px;
      }
    }
  }
}

// For screen width less than 768px
@include for-md-down {
  .myaccount-inputfield {
    width: 100%;
  }

  .myaccount-button {
    width: 120px;
  }

  .mb-myaccount-menu {
    height: 58px;
    display: flex;

    a {
      flex: 1;
      height: 100%;
      color: $black;
      font: 300 16px/normal $font-secondary;
      border-bottom: 1px solid $secondary-dark;

      display: flex;
      justify-content: center;
      align-items: center;

      &.active {
        color: $primary;
        font-weight: 600;
        background: $light-secondary;
        border-bottom: 2px solid $primary;
      }
    }
  }

  .myaccount-container {
    width: 428px;
    margin: 0 auto;
    flex-direction: column;

    .myaccount-left-container {
      display: none;
    }

    .myaccount-right-container {
      h1 {
        margin-top: 40px;
      }

      .myaccount-login-details {
        position: relative;
        margin: 126px 0 40px 0;
        padding: 126px 30px 40px 30px;

        .mb-myaccount-image {
          display: block;
          height: 172px;
          width: 172px;
          position: absolute;
          top: -86px;
          left: 50%;
          transform: translateX(-50%);

          .change-image-button {
            height: 52px;
            width: 52px;
            position: absolute;
            top: 5px;
            right: 5px;
            background: url("./images/camera-icon.svg") no-repeat center;
            border: none;
            cursor: pointer;
            z-index: 1;

            &:hover {
              filter: brightness(.9);
            }
          }

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 157.5px;
          }
        }
      }

      .myaccount-personal-details {
        margin-bottom: 40px;
        padding: 40px 30px;
      }
    }
  }

  .mb-myaccount-advertisement {
    display: block;
    margin-bottom: 20px;
  }
}

// For screen width less than 428px
@include for-sm-down {
  .myaccount-container {
    width: 100%;
  }
}